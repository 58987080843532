<template>
    <div>
        <CModal
                :title="this.dialog.title"
                :color="this.dialog.color"
                :show.sync="errModal"
                :centered="true"
                :close-on-backdrop="false"
        >
            {{this.dialog.message}}
        </CModal>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: 'CenterDialogs',
        data: function () {
            return {
                errModal: false,
                dialog: {
                    title: "",
                    color: "danger",
                    message: ""
                }
            }
        },
        mounted() {

        },

        created() {
        },

        beforeDestroy() {

        },

        methods: {},

        computed: {
            ...mapGetters({
                dialogMassage: 'dialog/onGetMessageDialog'
            })
        },

        watch: {
            dialogMassage: function (value) {
                this.dialog = value
                this.errModal = true
            },
        }
    }
</script>
