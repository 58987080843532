<template>
  <div>
    <CRow class="justify-content-center">

      <CCol sm="8" md="7">
        <CCard accent-color="success">
          <CCardBody>
            <CRow>
              <CCol class="text-center">
                <label for="input-vjs" class="border-white">
                  <div class="avatar-site2">
                    <img :src="url" class="img-avatar" alt="" width="100%">
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-vjs" type="file" accept="image/*" @change=uploadImage style="display: none;"/>
              </CCol>
            </CRow>
            <CRow>

            </CRow>
            <CRow>
              <CCol col="12">
                <CInput
                    :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                    label="Name"
                    placeholder="name"
                    v-model="objMint.name"
                >
                </CInput>
              </CCol>

            </CRow>
            <CRow v-if="serviceNfts.isRoyalty">
              <CCol col="12">
                <CInput
                    type="Number"
                    :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                    label="Royalty"
                    placeholder="royalty"
                    v-model="objMint.royalty"
                >
                  <template #append>
                    <CButton color="secondary">
                      <span class="text-white">%</span>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CSelect label="Category"
                         @update:value="onCategory"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         :options="categories"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3" class="mb-sm-2">
                <h6> Decription</h6>
              </CCol>
              <CCol md="9">
                <quill-editor class="mb-3" :content="objMint.decription"/>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CButton :disabled="!this.uploadImageState" @click="onMintNFT"
                         :color="(!this.uploadImageState == true)?'secondary':'success'" class="w-100">Mint NFT
                </CButton>
              </CCol>
            </CRow>

            <CRow class="mt-3">


              <CCol col="12">
                <!--                <img  style="width: 1.3rem" src="img/token/nextart.png" />-->
                <CInput
                    label="Price"
                    :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                    type="Number"
                    placeholder="0.0"
                    v-model="priceInWen"
                >
                  <template #append>
                    <CButton color="secondary" style="width: 8rem">
                      <img class="mr-2" height="22" src="img/token/nextart.png"/>
                      <span class="text-white">NEXT / USD</span>
                    </CButton>
                  </template>
                </CInput>
              </CCol>

              <CCol col="12">
                <CInput
                    label="Exprice Market"
                    type="datetime-local"
                    horizontal
                    v-model="expiresAt"
                />
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CButton :disabled="!isMint" @click="onApproveNFTTOMarket"
                         :color="(!this.isMint == true)?'secondary':'success'" class="w-100">Approve
                </CButton>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CButton :disabled="!isCreateOrder" @click="onCreateOrderToMarkets"
                         :color="(!this.isCreateOrder == true)?'secondary':'success'" class=" w-100">Sell Now
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>
    <!--    <MetamaskConnect user-message="msg" @onComplete="onComplete"/>-->


  </div>
</template>

<script>

import {address721, addressMaket} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import DialogConsistency from "@/project/views/pages/dialog/DialogConsistency";
import CenterDialogs from "@/containers/CenterDialogs";
import store from "@/store/store";

Vue.use(VueQuillEditor)
export default {
  name: 'MintNFT',
  components: {CenterDialogs, DialogConsistency},
  data: function () {
    return {
      url: "img/upload/upload.png",
      image: "",
      categories: [],
      decription: "",
      uploadImageState: false,
      priceInWen: 0,
      expiresAt: 0,
      dateTime: 0,
      objMint: {
        name: "",
        categories: "",
        decription: "",
        royalty:0,
      }
    }
  },
  mounted() {

  },

  created() {
    this.onConnectMataMaskAddress();
    this.$store.dispatch("gateway/onCategorys");
    this.$store.commit("erc721/setIsMint",false)
    this.$store.commit("eRCMarketPlace/setIsCrateOrdder",false)

    this.onAllownInfoNft();

  },

  beforeDestroy() {

  },

  methods: {

    onConnectMataMaskAddress(){
      this.$store.dispatch('metamask/onMetaMaskAddress')
    },

    onCategory(value, e) {
      this.objMint.categories = value;
    },

    onComplete(data) {
      console.log('data:', data);
    },
    onView() {
      alert(1)
    },

    uploadImage(e) {
      this.image = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.url = e.target.result;
        this.uploadImageState = true;
      };
    },

    onAllownInfoNft(){
      var data = {};
      data.address = store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('erc721/onAllownInfoNft', data)
    },

    onMintNFT() {
      // var obj = this.objMint;
      // obj.address = this.$store.getters['metamask/onGetMetaMaskAddress'];
      //

      var info = JSON.parse(localStorage.getItem('info'))
      let data = new FormData();
      data.append("creator", info._id);
      data.append("onwer", info._id);
      data.append('image', this.image, this.image.fileName);
      data.append("name", this.objMint.name);
      data.append("categorie", this.objMint.categories);
      data.append("decription", this.objMint.decription);
      data.append("contactAddress", address721);
      data.append("priceInCreator", this.objMint.royalty);

      this.$store.dispatch('gateway/onCreateNFTs', data)


    },


    onApproveNFTTOMarket() {
      var obj = {};
      obj.tokenId = this.$store.getters['erc721/objTokenId'];
      this.$store.dispatch('erc721/onApprove', obj);
    },


    onCreateOrderToMarkets() {

      var obj = {};
      obj.contactAddress = address721;
      obj.tokenId = this.$store.getters['erc721/objTokenId'];
      obj.priceInWen = this.priceInWen;
      obj.expiresAt = this.dateTime;

      this.$store.dispatch('eRCMarketPlace/onCreateOrderToMarkets', obj);
    }

  },

  computed: {
    ...mapGetters({
      categorie: 'gateway/objCategorys',
      serviceNfts: 'erc721/ObjServiceNfts',
      isMint: "erc721/objIsMint",
      isApptoveShopAccess: "erc721/objIsApptoveShopAccess",
      isCreateOrder: "eRCMarketPlace/objIsCreateOrder",
      reload: 'eRCMarketPlace/objReload'
    })
  },

  watch: {

    categorie(value) {

      var itemCategories = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {};
        obj.label = value[i].title;
        obj.value = value[i]._id;
        itemCategories.push(obj)
      }
      this.objMint.categories = value[0]._id;
      this.categories = itemCategories
    },

    serviceNfts(value){
      console.log("value",value)

    },

    expiresAt(value) {
      // var date = new Date(value).getTime()/1000
      this.dateTime = new Date(value).getTime() / 1000;
    },

    reload(value){
      this.$store.commit("erc721/setIsMint",false)
      this.$store.commit("eRCMarketPlace/setIsCrateOrdder",false)
      this.url = "img/upload/upload.png";
      this.priceInWen = 0;
      this.expiresAt = 0;
      this.dateTime = 0;
      this.objMint = {
        name: "",
        categories: "",
        decription: "",
        royalty:0,
      }
    }
  }
}
</script>
